@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
}

a {
  color: #000;
  text-decoration: none;
}

@font-face {
  font-family: 'Abril Fatface';
  font-weight: 500;
  src: url("/src/assets/font/AbrilFatface.ttf");
}

.Abril_Fatface {
  font-family: 'Abril Fatface' !important;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 9px;
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid var(--white);
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.active_sidebar:hover {
  background: #FFF !important;
}

.p_relative {
  position: relative;
}

.eye_btn button {
  position: absolute !important;
  right: 17px !important;
  top: 3px !important;
}

.action-button.edit {
  background: #80bd9e;
}

.action-button.delete {
  background: rgb(239, 83, 80);
}

.action-button.delete-btn {
  background: rgb(239, 83, 80);
}

.action-button {
  color: #fff;
  border: 0;
  padding: 6px 7px 3px 7px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.product_image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.product_details_image {
  width: 210px;
  height: 230px;
  object-fit: contain;
}

@media (max-width:600px) {
  .product_image {
    width: 90px;
    height: 90px;
  }

  /* .product_details_image {
    width: 190px;
    height: 190px;
  } */
}

.grid_container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(7, 1fr);
}

@media (max-width:1600px) {
  .grid_container {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width:1560px) {
  .grid_container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width:1080px) {
  .grid_container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width:850px) {
  .grid_container {
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width:550px) {
  .grid_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.compare_select .MuiSelect-select {
  padding: 9px 10px !important;
  border: 1px solid #a4a4a4 !important;
}

.compare_table {
  border-radius: 20px;
}

.product_data {
  padding: 10px !important;
}

.compare_table .MuiTableCell-root {
  font-size: 14px !important;
}

@media (max-width:900px) {
  .compare_table {
    border-radius: 0;
  }

  .product_data {
    padding: 8px 6px !important;
  }

  .compare_table .MuiTableCell-root {
    font-size: 12px !important;
  }

  .compare_table .MuiTypography-root {
    font-size: 12px !important;
  }
}



.compare_table .product_head {
  white-space: nowrap !important;
}



.compare_table_head {
  font-size: 16px !important;
  background: #80BD9E !important;
  color: #FFF !important;
  padding: 18px 15px !important;
  font-weight: 500 !important;
}

.compare_table .product_data {
  white-space: wrap !important;
  text-align: start !important;
}

.categoriesButton {
  color: #9F9F9F !important;
}

.MuiPagination-ul {
  justify-content: flex-end;
}

.MuiFormHelperText-root {
  color: red !important;
}

.autocomplate_search input {
  padding: 0 !important;
}

.MuiFormHelperText-root {
  border: unset !important;
}

.compare_table .MuiTableCell-root {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

.compare_table .MuiTableCell-root:last-of-type {
  border-right: unset !important;

}

.find_out_button .MuiStack-root {
  /* padding: 0 !important; */
  font-size: 14px !important;
  font-weight: 600 !important;
}